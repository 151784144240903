<template>
  <v-dialog id="dialog" v-model="dialog" max-width="600px">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" text class="primary--text">
        <v-icon class="mr-1">add_box</v-icon>
        Create Project
      </v-btn>
    </template>
    <v-card>
      <v-card-title>Create New Project</v-card-title>
      <v-card-text>
        <p class="mt-4">
          Project names are unique accross Synpse. You can invite your team to
          collaborate and share devices.
        </p>
        <form v-on:submit.prevent="createProject()">
          <v-text-field required label="Unique project name" v-model="project.name" :rules="projectRules" />

          <v-btn v-show="false" hidden type="submit">
          </v-btn>
        </form>
        <p>
          For project name, use letters, numbers and '-'.
        </p>
        <div v-show="createError">
          <v-alert dense type="error" outlined>
            {{ createError }}
          </v-alert>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn text v-on:click.native="dialog = false" class="primary--text">Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn text :disabled="loading" :loading="loading" v-on:click.native.stop="createProject()" class="primary">Create</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="stylus">
  .addbtn
    margin: 0px
    top: auto
    right: 20px
    bottom: 20px
    left: auto
    position: fixed
    z-index: 1
</style>

<script>
  // TODO: disallow end and beginning dash and underscore
  var projectNameRegexp = /^[a-z-0-9-_]+$/
  export default {
    data () {
      return {
        dialog: false,

        project: {
          name: ''
        },
        projectRules: [
          v => !!v || 'Project name is required',
          v => v.length <= 32 || 'Project name must be less than 32 characters',
          v => v.length >= 1 || 'Project name must be at least 1 character',
          v => v.search(projectNameRegexp) !== -1 || `Project name must be lowercase alphanumeric and consist only '-' and '_'` 
        ],
      }
    },

    watch: {
      dialog(val) {
        val || this.close()
      },
    },

    computed: {
      loading() {
        return this.$store.state.project.loading
      },
      error() {
        return this.$store.state.project.error
      },
      createError() {
        return this.$store.state.project.createError
      },
      createdProject() {
        return this.$store.state.project.createdProject
      }
    },

    methods: {
      createProject () {
        let payload = {
          name: this.project.name.toLowerCase()
        }
        this.$store.dispatch('CreateProject', payload).then(() => {
          if (this.createError != null) {
            // got error, don't reset form, let user fix the mistake
            return
          }
          this.$store.dispatch('GetProjectMemberships')
          this.$store.dispatch('SelectProject', this.createdProject.id)
          this.close()
        })
      },

      close() {
        this.dialog = false
        this.$nextTick(() => {
          this.project = Object.assign({}, {
            name: ''
          })
        })
        this.$store.dispatch('ClearCreateProjectError')
      }
    }
  }
</script>
